
//
// tables.scss
//
body[data-leftbar-theme="dark"] {
  table.dataTable tr.danger {
    background-color: rgba(255, 85, 85, 0.4);
  }

  table.dataTable tr.success {
    background-color: #30e31142;
  }

  table.dataTable tr.primary {
    background-color: rgba(48, 100, 163, 0.8);
  }

  table.dataTable tr.warning {
    background-color: #ffbc009e;
    color: #5b5b5b;
  }
  table.dataTable tr.info {
    background-color: rgb(69, 174, 188);
    color: #fff;
  }


  .table > :not(caption) > * > * {
    box-shadow: none;
  }

  table.dataTable tr.odd td {
    background-color: transparent;
  }
}

body[data-leftbar-theme="default"] {
  .table > thead > tr > td.danger, .table > tbody > tr > td.danger, .table > tfoot > tr > td.danger, .table > thead > tr > th.danger, .table > tbody > tr > th.danger, .table > tfoot > tr > th.danger, .table > thead > tr.danger > td, .table > tbody > tr.danger > td, .table > tfoot > tr.danger > td, .table > thead > tr.danger > th, .table > tbody > tr.danger > th, .table > tfoot > tr.danger > th {
    background-color: #f9bdbb;
  }

  .table > thead > tr > td.success, .table > tbody > tr > td.success, .table > tfoot > tr > td.success, .table > thead > tr > th.success, .table > tbody > tr > th.success, .table > tfoot > tr > th.success, .table > thead > tr.success > td, .table > tbody > tr.success > td, .table > tfoot > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr.success > th, .table > tfoot > tr.success > th {
    background-color: rgba(76, 175, 80, 0.5);
  }

  .table > thead > tr > td.warning, .table > tbody > tr > td.warning, .table > tfoot > tr > td.warning, .table > thead > tr > th.warning, .table > tbody > tr > th.warning, .table > tfoot > tr > th.warning, .table > thead > tr.warning > td, .table > tbody > tr.warning > td, .table > tfoot > tr.warning > td, .table > thead > tr.warning > th, .table > tbody > tr.warning > th, .table > tfoot > tr.warning > th {
    background-color: rgba(255, 152, 0, 0.5);
  }

  .table > thead > tr > td.primary, .table > tbody > tr > td.primary, .table > tfoot > tr > td.primary, .table > thead > tr > th.primary, .table > tbody > tr > th.primary, .table > tfoot > tr > th.primary, .table > thead > tr.primary > td, .table > tbody > tr.primary > td, .table > tfoot > tr.primary > td, .table > thead > tr.primary > th, .table > tbody > tr.primary > th, .table > tfoot > tr.primary > th {
    background-color: rgba(0, 158, 251, 0.5);
  }
  .table > thead > tr > td.primary, .table > tbody > tr > td.info, .table > tfoot > tr > td.info, .table > thead > tr > th.info, .table > tbody > tr > th.info, .table > tfoot > tr > th.info, .table > thead > tr.info > td, .table > tbody > tr.info > td, .table > tfoot > tr.info > td, .table > thead > tr.info > th, .table > tbody > tr.info > th, .table > tfoot > tr.info > th {
    background-color: rgb(44, 142, 248);
    color: #fff;
  }


  .table > :not(caption) > * > * {
    box-shadow: none;
  }
}
