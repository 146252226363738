.btn-group-vertical.position-fixed {
  z-index: 1;
}

#myAffix .nav {
  background: #d31414;
}

#myAffix .nav a {
  /*color: black;*/
  /*font-style: italic;*/
}


#myAffix .nav > li > a:hover,
#myAffix .nav > li > a:focus {
  background-color: #a6a6a670;

}

#myAffix .active {
  font-weight: bold;
  background-color: #eeeeee;

}


#myAffix .nav {
  display: none;
}

#myAffix .active .nav {
  display: block;
}

#myAffix  .nav a {
  font-weight: normal;
  font-size: .85em;
}

#myAffix .nav span {
  margin: 0 5px 0 2px;
}

#myAffix .nav .active a,
#myAffix .nav .active:hover a,
#myAffix .nav .active:focus a {
  font-weight: bold;
  padding-left: 30px;
  border-left: 5px solid #47b4c2;
}

#myAffix .nav .active span,
#myAffix .nav .active:hover span,
#myAffix .nav .active:focus span {
  display: none;
}


.side-affix.affix{
  top: 128px;
}

#myAffix {
  position: fixed;
  overflow-y: scroll;
  top: 85px;
  bottom: 0;
}

