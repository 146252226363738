@import "~chosen-js/chosen.css";
@import "~select2";
@import "~select2-bootstrap-5-theme";
@import "~chosen-bootstrap-theme";
@import "~angular-bootstrap-calendar";
@import "~datatables.net-bs5";
@import "~datatables.net-buttons-bs5";
@import "~datatables.net-searchpanes-bs5";
@import "~datatables.net-select-bs5";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
@import "~bootstrap-select/dist/css/bootstrap-select.css";
@import "./components/affix";
@import "./scss/themes/Hyper/icons";
@import "~font-awesome";
@import "/assets/styles/app/style.css";
.fa{
  font-family: FontAwesome !important;
}
@import "./scss/themes/Hyper 4.6.0-1/app";
@import "./structure/tables";

