li.row.list-unstyled.well.contracttask-object-child {
    margin: 0px;
    padding: 0px;
}

.contractpart-parent .well, .contractpart-parent .well-sm {
    padding: 0;
    margin: 0;
}

.contractpart-parent .form-group {
    margin-bottom: 0;
}
.contractpart-parent .form-row {
    padding: 5px;
}

.panel-info > .panel-heading {
    background-color: #47b4c2;
}

.list-group-item-info {
    background-color: #47b4c24f;
}


.list-group-item-info .h6 {
    color: white;
}

#myAffix .nav {
    background: #eeeeee;
}

#myAffix .nav a {
    /*color: black;*/
    /*font-style: italic;*/
}


#myAffix .nav > li > a:hover,
#myAffix .nav > li > a:focus {
    background-color: #a6a6a670;

}

#myAffix .active {
    font-weight: bold;
    background-color: #eeeeee;

}


#myAffix .nav {
    display: none;
}

#myAffix .active .nav {
    display: block;
}

#myAffix  .nav a {
    font-weight: normal;
    font-size: .85em;
}

#myAffix .nav span {
    margin: 0 5px 0 2px;
}

#myAffix .nav .active a,
#myAffix .nav .active:hover a,
#myAffix .nav .active:focus a {
    font-weight: bold;
    padding-left: 30px;
    border-left: 5px solid #47b4c2;
}

#myAffix .nav .active span,
#myAffix .nav .active:hover span,
#myAffix .nav .active:focus span {
    display: none;
}


.side-affix.affix{
    top: 128px;
}
.img-wrapper {
    width: 100%;
    height: 80px;
    position: relative;
}

.img-wrapper img {
    position: absolute;
    max-width: 50%;
    bottom: 0;
}

.img-wrapper img:nth-of-type(1) {
    left: 0;
}

.img-wrapper img:nth-of-type(2) {
    right: 0;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #666666;
}

.navbar-inverse {
    background-color: #ffffff;
}

.panel-info > .panel-heading {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.panel-warning > .panel-heading {
    color: #ffffff;
    background-color: #f97676;
    border-color: #f97676;
}
.table-responsive{
    table-layout: fixed;
    word-wrap: break-word;
}


table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 6px 16px;
    font-size: 13px;
    line-height: 1.846;
    border-radius: 3px;
    color: #444;
    background-color: #fff;
    border-color: transparent;
    user-select: none;
    text-transform: uppercase;
    border: 1px solid transparent;
    -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    display: block;
    position: absolute;
    font-family: "FontAwesome";
    content: "\f00e";
    top: initial;
    left: 8px;
    height: auto;
    width: auto;

}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:hover:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:hover:before {
    background-color: #e6e6e6;
}

.affix-container {
    height: 83px;
}

.required.control-label:after {
    content: " *";
    color: red;
}

.yadcf-filter-wrapper {
    width: 100%;
}

.navbar-inverse .navbar-toggle .icon-bar {
    /*background-color: #47b3c1;*/
}

.yadcf-filter-wrapper-inner {
    border: none;
}

@media (min-width: 1025px) {
    .yadcf-filter-reset-button {
        text-decoration: none;
        font-size: 0;
        background: none;
        border: none;
        opacity: .3;
    }

    .yadcf-filter-reset-button {
        display: inline-block;
    }

    .yadcf-filter-wrapper {
        display: flex;
        white-space: normal;
        margin-top: 7px;
    }

    .yadcf-filter-reset-button:before {
        font-family: Glyphicons Halflings;
        content: "\E020";
        font-size: 16px;
    }

}

@media (max-width: 1024px) {
    .yadcf-filter-reset-button {
        display: none;
    }
}

.chosen-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

h1, .h1, h2, .h2, h3, .h3 {

    margin-top: 5px;

    margin-bottom: 10px;

}

.panel {

    margin-bottom: 10px;

}

.alert-info {

    color: #31708f;

    background-color: #d9edf7;

    border-color: #bce8f1;

}

.navbar-default .navbar-nav > li > a:hover {

    color: #212121;

    background-color: #eeeeee;

}

.navbar > .container-fluid .navbar-left .navbar-brand {

    margin-left: 0px;

}

.navbar-brand > h2 {

    margin-left: 15px;

    display: inline;

    color: white;

    font-size: 24px;

}

.navbar-inverse .navbar-brand {

    color: white;

}

.navbar-left .navbar-brand {

    text-align: left;

    width: auto;

}

.navbar-brand {

    position: absolute;

    width: 100%;

    left: 0;

    top: 0;

    text-align: center;

    margin: auto;

    padding: 0px;

    /*height: 100%;*/

}

.navbar-toggle {

    z-index: 3;

}

.navbar-brand > img {

    display: inline;

    height: 90%;

    float: right;

    line-height: 65px;

    font-size: medium;

    margin: 3px;
    image-rendering: -webkit-optimize-contrast;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

}

.navbar-right {

    margin-right: -5px;

}

@media (max-width: 1024px) {

    .navbar-brand > img {

        margin-right: 15px;

    }

    .navbar > .container-fluid .navbar-left .navbar-brand {

        margin-left: 50px;

    }

    .navbar-brand {

        /*left: 50px;*/

    }

    .navbar-header {

        float: none;

    }

    .navbar-toggle {

        display: block;

    }

    .navbar-collapse {

        border-top: 1px solid transparent;

        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);

    }

    .navbar-collapse.collapse {

        display: none !important;

    }

    .navbar-nav {

        float: none !important;

        margin: 0px;

    }

}

.navbar-nav > li {

    float: none;

}

.navbar-nav > li > a {

    padding-top: 10px;

    padding-bottom: 10px;

}

.fa-stack-1x {

    line-height: 41px;

}

.fa-building-o.fa-stack-2x {

    font-size: 49px;

    top: 2px;

}



.navbar-inverse .navbar-toggle .icon-bar {

    background-color: rgba(255, 255, 255, 0.5);

}

#tasksTable {

    margin: 0 auto;

    width: 100%;

    min-width: 500px;

    clear: both;

    border-collapse: collapse;

    /*table-layout: fixed; /*add this*/

    /*word-wrap:break-word; /*and this*/

}

.scroll-top {

    z-index: 1049;

    position: fixed;

    right: 4%;

    bottom: 4%;

    width: 50px;

    visibility: hidden;

}

.scroll-top .btn {

    width: 50px;

    height: 50px;

    border-radius: 100%;

    line-height: 34px;

    padding: auto auto auto auto;

}

.scroll-top .btn:focus {

    outline: 0;

}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {

    background-color: rgba(30, 144, 255, 0.5);

}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {

    background-color: rgba(30, 144, 255, 0.5);

}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {

    background-color: rgba(255, 152, 0, 0.5);

}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {

    background-color: rgba(255, 152, 0, 0.5);

}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {

    background-color: rgba(76, 175, 80, 0.5);

}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {

    background-color: rgba(76, 175, 80, 0.5);

}
.table > thead > tr > td.primary,
.table > tbody > tr > td.primary,
.table > tfoot > tr > td.primary,
.table > thead > tr > th.primary,
.table > tbody > tr > th.primary,
.table > tfoot > tr > th.primary,
.table > thead > tr.primary > td,
.table > tbody > tr.primary > td,
.table > tfoot > tr.primary > td,
.table > thead > tr.primary > th,
.table > tbody > tr.primary > th,
.table > tfoot > tr.primary > th {

    background-color: rgba(0, 158, 251, 0.5);

}

.table-hover > tbody > tr > td.primary:hover,
.table-hover > tbody > tr > th.primary:hover,
.table-hover > tbody > tr.primary:hover > td,
.table-hover > tbody > tr:hover > .primary,
.table-hover > tbody > tr.primary:hover > th {

    background-color: rgba(0, 158, 251, 0.5);

}

.side-menu #dropdown .panel-body .navbar-nav {

    height: auto;

    max-height: 200px;

    overflow-x: hidden;

}

/*



.navbar-brand {



    padding: 2.5px 15px;



}



*/

.clickable-row, .chartjs-hidden-iframe {

    cursor: pointer;

}

#calendar-table tr {

    cursor: pointer;

}

.mainBars rect {

    cursor: pointer;

}

.well-sm {

    page-break-after: always;
    background-color: #eeeeee;
    margin-bottom: 10px;

}

.vp-print-logo {

    background-color: #222;

    border-top-left-radius: 4px;

    top: -50px;

    -webkit-box-sizing: border-box;

    -moz-box-sizing: border-box;

    box-sizing: border-box;

    display: none;

    height: 36px;

    padding: 6px;

    position: fixed;

    right: -50px;

    z-index: 99999;

}

.vp-print-logo-img {

    max-height: 36px;

    max-width: 36px;

    display: none;

}

.show-print-only {

    display: none;

}

.img-barChartReport {

    height: 341px;

}

@media print {


    .show-print-only {

        display: block;

    }

    .hide-print-only {

        display: none !important;

    }

    /*



    @page {



        margin-top: 2cm;



    }



    */
    .sf-toolbarreset, .sf-minitoolbar {

        display: none !important;

    }

    .vp-print-logo {

        display: block;

    }

    .vp-print-logo-img {

        display: block;

    }

}

@media print {

    /*



    table { page-break-inside:auto }



    tr    { page-break-inside:avoid; page-break-after:auto }



    thead { display:table-header-group }



    tfoot { display:table-footer-group }



    */
    tr.success {

        background-color: #dff0d8 !important;

    }

    .table > thead > tr > td.danger, .table > tbody > tr > td.danger, .table > tfoot > tr > td.danger, .table > thead > tr > th.danger, .table > tbody > tr > th.danger, .table > tfoot > tr > th.danger, .table > thead > tr.danger > td, .table > tbody > tr.danger > td, .table > tfoot > tr.danger > td, .table > thead > tr.danger > th, .table > tbody > tr.danger > th, .table > tfoot > tr.danger > th {

        background-color: #f9bdbb !important;

    }

    .table > thead > tr > td.success, .table > tbody > tr > td.success, .table > tfoot > tr > td.success, .table > thead > tr > th.success, .table > tbody > tr > th.success, .table > tfoot > tr > th.success, .table > thead > tr.success > td, .table > tbody > tr.success > td, .table > tfoot > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr.success > th, .table > tfoot > tr.success > th {

        background-color: #dff0d8 !important;

    }

    .table > thead > tr > td.warning, .table > tbody > tr > td.warning, .table > tfoot > tr > td.warning, .table > thead > tr > th.warning, .table > tbody > tr > th.warning, .table > tfoot > tr > th.warning, .table > thead > tr.warning > td, .table > tbody > tr.warning > td, .table > tfoot > tr.warning > td, .table > thead > tr.warning > th, .table > tbody > tr.warning > th, .table > tfoot > tr.warning > th {

        background-color: #ffe0b2 !important;

    }

}

.mainBars rect {

    shape-rendering: auto;

    fill-opacity: 0;

    stroke-width: 0.5px;

    stroke: rgb(0, 0, 0);

    stroke-opacity: 0;

}

.subBars {

    shape-rendering: crispEdges;

}

.edges {

    stroke: none;

    fill-opacity: 0.5;

}

.clickable {

    cursor: pointer;

}

.panel-heading span {

    margin-top: -30px;

    font-size: 15px;

}

.modal {

    position: fixed;

    top: 3%;

    right: 3%;

    left: 3%;

    width: auto;

    margin: 0;

}

.modal-body {

    height: 60%;

}

.modal-body {

    /*max-height: 350px;*/

    padding: 15px;

    overflow-y: auto;

    -webkit-overflow-scrolling: touch;

}

#modal-taak-info {

    height: 100%;

    margin: 0;

    padding: 0;

}

#modal-taak-info .modal-body {

    max-height: 100%;

    height: auto;

    min-height: 100%;

    border-radius: 0;

}

.affix {

    top: 64px;

    z-index: 1029;

}

.affix-custom .btn {

    margin-right: 5px;

}

.affix-custom {

    margin-left: -15px;

    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);

    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);

    background-color: white;

}

.breadcrumb {

    border-radius: 0px;

}

.btn-circle {

    width: 30px;

    height: 30px;

    text-align: center;

    padding: 6px 0;

    font-size: 12px;

    line-height: 1.428571429;

    border-radius: 15px;

}

.btn-circle.btn-lg {

    width: 50px;

    height: 50px;

    padding: 10px 16px;

    font-size: 18px;

    line-height: 1.33;

    border-radius: 25px;

}

.btn-circle.btn-xl {

    width: 70px;

    height: 70px;

    padding: 10px 16px;

    font-size: 24px;

    line-height: 1.33;

    border-radius: 35px;

}

.container-2 {

    margin-top: 120px

}

.fa-building-o.fa-stack-2x {

    font-size: 1.5em;

    top: 6px;

}

i.fa.fa-check.fa-stack-1x,
i.fa.fa-certificate.fa-stack-1x {

    left: 29px;

    top: 9px;

}

i.fa.fa-certificate.fa-stack-1x {

    font-size: 1.5em;

}

i.fa.fa-check.fa-stack-1x {

    color: #2196f3;

}

@media (max-width: 1024px) {

    .navbar-toggle {
        border: 0;
        position: absolute;
        float: left;
        left: 17px;
        top: 2px;
        padding: 0px;
        margin: 0px;
        border-radius: 0;
    }

    .navbar-brand {

       height: 100%;

    }

    tr.clickableRow {

        cursor: pointer;

    }

    .well {

        background-color: rgba(33, 150, 243, 0.07);

        border: none;

    }

    ul.collection {

        padding-left: 15px;

        padding-right: 15px;

    }

    .navbar {

        margin-bottom: 0;

    }

    .fixed-bottom {

        position: absolute;

        bottom: 10%;

    }

    .zero-padding-right {

        padding-right: 0px;

    }

    #mydiv {

        position: relative;

        top: 0;

        left: 0;

        width: 100%;

        height: 100%;

        z-index: 1000;

        background-color: grey;

        opacity: .8;

    }

    .ajax-loader {

        position: absolute;

        left: 50%;

        top: 50%;

        margin-left: -32px; /* -1 * image width / 2 */

        margin-top: -32px; /* -1 * image height / 2 */

        display: block;

    }

    .scroll-top-wrapper {

        position: fixed;

        visibility: visible;

        cursor: pointer;

        opacity: 0.8;

        overflow: hidden;

        text-align: center;

        z-index: 99999999;

        width: 80px;

        height: 48px;

        line-height: 48px;

        right: 30px;

        bottom: 30px;

        padding-top: 2px;

        -webkit-transition: all 0.5s ease-in-out;

        -moz-transition: all 0.5s ease-in-out;

        -ms-transition: all 0.5s ease-in-out;

        -o-transition: all 0.5s ease-in-out;

        transition: all 0.5s ease-in-out;

    }

    .panel h1, .panel .h1, .panel h2, .panel .h2, .panel h3, .panel .h3 {

        margin-top: 0px;

        margin-bottom: 0px;

    }

    .container-fluid.zero-padding {

        padding: 0px;

    }

    .material-button-anim {

        position: fixed;

        top: 69px;

        right: 5px;

        border-radius: 0px;

    }

    .material-button-back {

        position: fixed;

        top: 129px;

        right: 5px;

        border-radius: 0px;

    }

    .material-button-anim {

        text-align: center;

        max-width: 260px;

        margin: 0 auto 20px;

    }

    .material-button {

        position: relative;

        top: 0;

        z-index: 1;

        width: 50px;

        height: 50px;

        font-size: 1.5em;

        color: #fff;

        background: #2C98DE;

        border: none;

        border-radius: 50%;

        box-shadow: 0 3px 6px rgba(0, 0, 0, .275);

        outline: none;

    }

    .material-button.back {

        background: #ffb524;

    }

}

@media (max-width: 1024px) {

    .fa-stack-1x {

        line-height: 41px;

    }

    .fa-building-o.fa-stack-2x {

        font-size: 49px;

        top: 2px;

    }

    #yadcf-filter-wrapper--tasksTable-2{
        width: 200px !important;
        max-width: 200px !important;
    }
    #yadcf-filter-wrapper--tasksTable-3{
        width: 200px !important;
        max-width: 200px !important;
    }
    #yadcf-filter-wrapper--tasksTable-4{
        width: 200px !important;
        max-width: 200px !important;
    }

}


.tr-bag-kadaster > td{
    transition: opacity 2000ms;
}
.tr-bag-kadaster.removing > td{
    opacity: 0;
}

.modal-body button {
    display: none;
}







